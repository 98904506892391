import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Translate } from 'react-i18nify';

const style = theme => ({
  statusBanner: {
    padding: '10px',
    fontSize: '15px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    margin: '10px 15px',
    display: 'flex',
    flex: 1,
  },
  statusMessage: {
    padding: '5px',
    alignText: 'center',
  },
  button: {
    flex: 1,
  },
});

class StatusBannerComponent extends Component {
  render() {
    const {
      classes,
      accountId,
      showButton = false,
      migrateRedirect,
      generateMigrateLink,
    } = this.props;

    const redirectSecureEdge = () => {
      // TO DO: MAY NEED TO UPDATE THIS REDIRECT BEHAVIOR
      window.location.href = `https://se.qa.cudaops.com/migrate/url="${
        migrateRedirect[accountId]
      }"`;
    };

    const migrate_btn = () => {
      generateMigrateLink({
        account_id: accountId,
        individual: true,
      });
    };

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              data-test-id="migrationBanner"
              className={classes.statusBanner}
            >
              <div className={classes.statusMessage}>
                <Translate value="components.migrationToSecureEdge.migrateStatusMessageRedirect" />
              </div>
              {showButton && (
                <div className={classes.button}>
                  <Button
                    onClick={() => {
                      migrateRedirect === ''
                        ? migrate_btn()
                        : redirectSecureEdge();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    {migrateRedirect === '' ? (
                      <Translate value="components.migrationToSecureEdge.migrateButton" />
                    ) : (
                      'Redirect'
                    )}
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style, { name: 'StatusBanner' })(
  StatusBannerComponent
);
