import { connect } from 'react-redux';

import StatusBanner from '../components/StatusBanner';

import { redirectSecureEdge, generateMigrateLink } from '../actions/account';

const mapStateToProps = (state, ownProps) => ({
  accountId: state.account.selected,
  migrateRedirect: state.account.migrateLink || '',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  redirectSecureEdge: () => {
    dispatch(redirectSecureEdge());
  },
  generateMigrateLink: params => {
    dispatch(generateMigrateLink(params));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusBanner);
