import { connect } from 'react-redux';

import Accounts from '../components/Accounts';

import {
  initAccountDashboard,
  retrieveAccount,
  selectAccount,
  getAccountInstallers,
  redirectSecureEdge,
  generateMigrateLink,
  toggleSupport,
} from '../actions/account';

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  migrateRedirect: state.account.migrateLink || '',
  migrate_to_secure_edge: state.account.migrate_to_secure_edge || false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAccountInstallers: account => {
    dispatch(getAccountInstallers(account));
  },
  selectAccount: (id, name) => {
    dispatch(selectAccount(id, name));
  },
  initData: () => {
    dispatch(initAccountDashboard());
  },
  redirectSecureEdge: () => {
    dispatch(redirectSecureEdge());
  },
  generateMigrateLink: params => {
    console.log(' ---> params: ', params);
    dispatch(generateMigrateLink(params));
  },
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
  toggleSupport: account_id => {
    dispatch(toggleSupport(account_id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
