import React, { Component } from 'react';
import { Translate, translate } from 'react-i18nify';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';

import SectionHeader from '../components/SectionHeader';
import { AccountIcon } from '../components/icons';
import SearchBar from '../components/SearchBar';
import PaginatorActions from './table/PaginatorActions';
import SettingsItemSmall from './settings/SettingsItemSmall';
// import StatusBanner from './StatusBanner';
import StatusBanner from '../containers/StatusBanner';

import CudaTableTitle from '../components/CudaTableTitle';
import AgentsModule from '../containers/overview/AgentsModule';
import SubscriptionModule from '../containers/overview/SubscriptionModule';
import DeployModal from '../containers/overview/DeployModal';
import Support from '../containers/MSPSupportModule';
import Auth from '../lib/Auth';
import Paper from './CudaPaper';

import { SECUREEDGE_URL } from '../lib/Env';

const style = theme => ({
  thTitle: {
    textAlign: 'center',
  },
  tableTitle: {
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
  },
  manageButton: {
    height: '32px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: 0,
  },
  nameColumn: {
    width: '75%',
  },
  deploy: {
    position: 'absolute',
    color: theme.colors.barracudaBlue,
    textDecoration: 'none',
    marginLeft: '8px',
    maxWidth: '125px',
  },
  numericCell: {
    textAlign: 'center',
  },
  actionColumnHeader: {
    width: '25%',
  },
  actionColumn: {
    width: '25%',
    textAlign: 'right',
  },
  deployButton: {
    height: '32px',
    lineHeight: '16px',
    padding: '8px 15px',
    fontWeight: 300,
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  placeholder: {
    height: '194px',
    width: '100%',
    backgroundColor: '#DDDDDD',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div.title': {
      fontSize: '18px',
      lineHeight: '21px',
      color: 'rgba(0,0,0,0.7)',
    },
    '& div.subTitle': {
      fontSize: '14px',
      lineHeight: '16px',
      color: 'rgba(0,0,0,0.5)',
    },
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    overflowX: 'auto',
    width: '100%',
  },
  statsColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& >div': {
      marginBottom: '24px',
    },
  },
  accounts: {},
  headerSection: {
    margin: '10px 0px 0px 20px',
  },
  searchWrapper: {
    margin: '14px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  licenses: {
    color: 'rgba(0,0,0,0.5)',
  },
  link: {
    color: theme.colors.barracudaBlue,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    textTransform: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonWrappers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorMessage: {
    padding: '10px',
    fontSize: '15px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    margin: '10px 15px',
  },
});

const fields = ['sort_name', 'threats', 'quarantine', 'total', 'user'];

class AccountComponent extends Component {
  state = {
    query: '',
    sortDirection: ['asc', 'none', 'none', 'none', 'none'],
    index: 0,
    pageIndex: 0,
    itemsPerPage: 20,
    pageOptionOpen: false,
    showModal: false,
    modalTitle: null,
    anchorEl: null,
  };

  componentDidMount() {
    const dtoken = Auth.get_sso_token();
    this.props.initData();
    this.props.retrieveAccount(
      dtoken.email,
      dtoken.account,
      dtoken.accounts,
      dtoken.services,
      dtoken.sub,
      dtoken.zoneinfo
    );
  }

  handleClick = event => {
    this.setState({ pageOptionOpen: true, anchorEl: event.currentTarget });
  };

  handleCloseOption = () => {
    this.setState({ pageOptionOpen: false });
  };

  handleRequestClose = option => {
    this.setState({ itemsPerPage: option, pageOptionOpen: false });
  };

  handleFirst = () => {
    this.setState({ pageIndex: 1 });
  };

  handleLast = total => {
    this.setState({
      pageIndex:
        Math.round(total / this.state.itemsPerPage) +
        (total % this.state.itemsPerPage > 0 ? 1 : 0),
    });
  };

  handleNext = () => {
    this.setState({ pageIndex: this.state.pageIndex + 1 });
  };

  handlePrev = () => {
    this.setState({ pageIndex: this.state.pageIndex - 1 });
  };

  closeModal() {
    this.setState({ showModal: false });
  }

  handleChangePage = (event, pageIndex) => {
    this.setState({ pageIndex });
  };

  handleChangeRowsPerPage = event =>
    this.setState({ itemsPerPage: event.target.value });

  openModal(account, name) {
    const { getAccountInstallers } = this.props;
    getAccountInstallers(account);
    this.setState({ showModal: true, modalTitle: name });
  }

  toggle(index) {
    let value = this.state.sortDirection[index];
    let sortDirection = ['none', 'none', 'none', 'none', 'none'];
    if (value === 'asc') {
      value = 'desc';
    } else {
      value = 'asc';
    }

    sortDirection[index] = value;
    this.setState({ sortDirection: sortDirection, index: index });
  }

  render() {
    const options = [5, 10, 20];
    const {
      classes,
      account,
      selectAccount,
      redirectSecureEdge,
      generateMigrateLink,
      toggleSupport,
      migrateRedirect,
    } = this.props;
    const subscriptions = account.subscriptions;
    let accountList = [];
    if (account.init) {
      const accountFiltered = account.accounts.filter(a => {
        return (
          a.account_name
            .toLowerCase()
            .match(this.state.query.toLowerCase(), 'g') &&
          account['dashboardInfo'][Number(a.id)]
        );
      });

      const accountMap = accountFiltered.map(a => {
        return {
          id: a.id,
          name: a.account_name,
          sort_name: a.account_name.toLowerCase(),
          threats: account['dashboardInfo'][a.id]['threats'],
          used: account['dashboardInfo'][a.id]['used'],
          total: account['dashboardInfo'][a.id]['total'],
          user: account['dashboardInfo'][a.id]['user_total'],
          hasWca: account['dashboardInfo'][a.id]['hasWca'],
          quarantine: account['dashboardInfo'][a.id]['quarantine'],
          migrate_to_secure_edge:
            account['dashboardInfo'][a.id]['migrated_to_secure_edge'],
        };
      });

      const orderBy = fields[this.state.index];
      const sortDirection = this.state.sortDirection[this.state.index];
      accountList = accountMap.sort((a, b) => {
        return sortDirection === 'desc'
          ? b[orderBy] < a[orderBy]
            ? -1
            : 1
          : a[orderBy] < b[orderBy]
          ? -1
          : 1;
      });
    }

    if (migrateRedirect !== '') {
      // alert('Redirectory to Secure Edge to finish migration process');
      // window.location.href = migrateRedirect;
      console.log('migrateRedirect: ', migrateRedirect);
    }

    const { pageIndex, itemsPerPage } = this.state;
    const total = accountList.length;
    const start = pageIndex * itemsPerPage;
    const end = start + itemsPerPage;
    const displayList = accountList.slice(start, end);
    const { supportAccess, supportAccessLoading } = account;
    const allAccountsIsMigrated =
      displayList.filter(a => a.migrated_to_secure_edge).length === total; // TODO: Implement variable for migrated accounts

    const migrate_btn = account_id => {
      // alert('Migrate From MSP VIEW: ' + account_id);

      const migrate_config_link = generateMigrateLink({
        account_id,
        mspView: true,
      });
    };

    const redirect_btn = link => {
      window.location.href = `https://se.qa.cudaops.com/migrate_config="${link}"`;
      // TO DO: VET WHETHER OR NOT TIS WILL BE A GOOD WAY TO REDIRECT WITH THE URL
      /*
      const form = document.createElement("form");
      form.method = "GET";
      form.action = SECUREEDGE_URL;


      
      const userInput = document.createElement("input");
      userInput.type = "hidden";
      userInput.name = "user";
      userInput.value = "admin";
      
      const passInput = document.createElement("input");
      passInput.type = "hidden";
      passInput.name = "password";
      passInput.value = "test";

      const authInput = document.createElement("input");
      authInput.type = "hidden";
      authInput.name = "auth_type";
      authInput.value = "Local";

      const timeInput = document.createElement("input");
      timeInput.type = "hidden";
      timeInput.name = "et";
      timeInput.value = Math.floor(Date.now() / 1000);
      
      form.appendChild(userInput);
      form.appendChild(passInput);
      form.appendChild(authInput);
      form.appendChild(timeInput);
      
      document.body.appendChild(form);
      form.submit();
      */

      //sessionStorage.setItem("configurationUuserInfo", JSON.stringify({ userId: 123 }));
      //window.location.href = "https://se.qa.cudaops.com";

      // TO DO  :
      //  * need to find out how to allow for logging into to the secure edge account
      //  * need to find out how to pass configuration link to s3 bucket when redirecting to secure edge
      // window.location.href = 'https://se.qa.cudaops.com/migrate_config="http://urlofconfig.com"';
      // redirectSecureEdge(); // TODO: find way to handle preflight check.
    };

    return (
      <DocumentTitle title={translate('components.dashboard.page_title')}>
        <div>
          {!allAccountsIsMigrated && <StatusBanner />}
          <SectionHeader
            icon={<AccountIcon />}
            title={translate('components.dashboard.accounts')}
            tooltipText="Tooltip Text"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className={classes.statsColumn}>
              <SubscriptionModule elevation={2} />
              {(subscriptions.atp || subscriptions.af) && <AgentsModule />}
              <Support />
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={classes.accounts}>
                <Grid container>
                  <Grid item xs={12} sm={6} lg={8}>
                    <div className={classes.headerSection}>
                      <div className={classes.title}>Accounts</div>
                      <div className={classes.subtitle}>
                        <Translate value="shared.last_7_days" toggle="none" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <div className={classes.searchWrapper}>
                      <SearchBar
                        id="account-search"
                        value={this.state.query}
                        onChange={value => {
                          this.setState({ query: value });
                        }}
                        placeholder={translate('shared.searchByName')}
                        autoFocus={false}
                        disabled={false}
                        height="32px"
                        invert
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.tableWrapper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.nameColumn}>
                            <CudaTableTitle
                              value={translate(
                                'components.dashboard.accountName'
                              )}
                              sort={true}
                              toggle={this.state.sortDirection[0]}
                              onClick={() => {
                                this.toggle(0);
                              }}
                            />
                          </TableCell>
                          {subscriptions.atp && (
                            <TableCell className={classes.numericCell}>
                              <CudaTableTitle
                                value={translate(
                                  'components.dashboard.threatsDetected'
                                )}
                                sort={true}
                                toggle={this.state.sortDirection[1]}
                                onClick={() => {
                                  this.toggle(1);
                                }}
                              />
                            </TableCell>
                          )}
                          {subscriptions.atp && (
                            <TableCell className={classes.numericCell}>
                              <CudaTableTitle
                                value={translate(
                                  'components.dashboard.threatsInQuarantine'
                                )}
                                sort={true}
                                toggle={this.state.sortDirection[2]}
                                onClick={() => {
                                  this.toggle(2);
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell className={classes.actionColumnHeader}>
                            <Translate
                              className={classes.tableTitle}
                              value="Actions"
                              toggle="none"
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {account.dashboardLoading ? (
                          <TableRow>
                            <TableCell
                              data-test-id="accountTableLoading"
                              className={classes.noResults}
                              colSpan="7"
                            >
                              <CircularProgress data-test-id="loadingSpinner" />
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {!account.dashboardLoading && displayList.length > 0
                          ? displayList.map((a, i) => {
                              return (
                                <TableRow key={a.id}>
                                  <TableCell className={classes.nameColumn}>
                                    {a.name}
                                  </TableCell>
                                  {subscriptions.atp && (
                                    <TableCell className={classes.numericCell}>
                                      {a.threats}
                                    </TableCell>
                                  )}
                                  {subscriptions.atp && (
                                    <TableCell
                                      className={classes.numericCell}
                                      style={{
                                        color: a.threats ? '#DD1E31' : '',
                                      }}
                                    >
                                      {a.quarantine}
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.actionColumn}>
                                    <div className={classes.buttonWrappers}>
                                      <div>
                                        <Button
                                          id={`manage-btn${i}`}
                                          color="primary"
                                          variant="contained"
                                          onClick={() => {
                                            selectAccount(a.id, a.name);
                                            this.props.history.push(
                                              '/dashboard'
                                            );
                                          }}
                                          className={classes.manageButton}
                                        >
                                          Manage
                                        </Button>
                                      </div>
                                      {!a.migrate_to_secure_edge && (
                                        <div border="120px">
                                          <Button
                                            id={`migrate-btn${a.id}`}
                                            color="secondary"
                                            variant="contained"
                                            className={classes.manageButton}
                                            onClick={() =>
                                              migrateRedirect[a.id]
                                                ? redirect_btn(
                                                    migrateRedirect[a.id]
                                                  )
                                                : migrate_btn(a.id)
                                            }
                                          >
                                            {migrateRedirect[a.id] ? (
                                              <Translate value="components.dashboard.redirect" />
                                            ) : (
                                              <Translate value="components.dashboard.migrate" />
                                            )}
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : null}
                        {!account.dashboardLoading &&
                        accountList.length === 0 ? (
                          <TableRow>
                            <TableCell
                              className={classes.noResults}
                              colSpan="7"
                            >
                              <Translate value="shared.noResults" />
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow data-test-id="accounts-pagination-wrapper">
                          <TablePagination
                            colSpan={6}
                            count={total}
                            rowsPerPageOptions={options}
                            rowsPerPage={this.state.itemsPerPage}
                            page={this.state.pageIndex}
                            backIconButtonProps={{
                              'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                              'aria-label': 'Next Page',
                            }}
                            SelectProps={{
                              'data-test-id': 'accounts-selection',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={props => (
                              <PaginatorActions first last {...props} />
                            )}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                    <DeployModal
                      open={this.state.showModal}
                      title={this.state.modalTitle}
                      onClose={() => {
                        this.closeModal();
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

export default withStyles(style, { name: 'Accounts' })(AccountComponent);
