import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import update from 'immutability-helper';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import debounce from './debounce';
import * as Types from '../actions/block_page_settings';
import * as AppTypes from '../actions/app';
import { getAccountId } from '../selectors/account';
import { getBlockPageSettings } from '../selectors/block_page_settings';
import Api from './Api';

const he = require('he');

const hasOwn = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

export default (
  state = {
    original: {
      content: '',
      customHTML: '',
      header: '',
      template: 'default',
    },
    default: {
      content: '',
      customHTML: '',
      header: '',
      template: 'default',
    },
    changes: {},
    saving: false,
    errors: [],
    loading: false,
    logo: '',
    editorState: EditorState.createEmpty(),
  },
  action
) => {
  switch (action.type) {
    case Types.BLOCK_PAGE_FETCH_INFO: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.BLOCK_PAGE_FETCH_INFO_SUCCESS: {
      const { data } = action;
      const template = data.custom === 2 ? 'custom' : 'default';
      let contentState;

      if (template === 'custom') {
        contentState = ContentState.createFromText(data.custom_html);
      } else {
        contentState = stateFromHTML(data.content);
      }

      return update(state, {
        original: {
          $set: {
            content: stateToHTML(stateFromHTML(data.content)),
            header: data.header,
            customHTML: data.custom_html,
            template,
          },
        },
        default: {
          $set: {
            content: stateToHTML(stateFromHTML(data.default_content)),
            header: data.default_header,
            customHTML: data.default_custom,
            template,
          },
        },
        logo: { $set: data.logo_url },
        template: { $set: data.body },
        editorState: {
          $set: EditorState.push(state.editorState, contentState),
        },
        loading: { $set: false },
      });
    }
    case Types.BLOCK_PAGE_FETCH_INFO_FAILURE:
      console.log('action.errors', action);

      return {
        ...state,
        loading: false,
        errors: action.errors || ['Failed to fetch settings'],
      };
    case Types.BLOCK_PAGE_CHANGE_TEMPLATE: {
      const { original } = state;
      const defaultSettings = state.default;
      const { template, ...changes } = state.changes;
      const revert = action.template === original.template;
      let contentState;

      if (action.template === 'custom') {
        contentState = ContentState.createFromText(
          original.customHTML || defaultSettings.customHTML,
          '\n'
        );
      } else {
        contentState = stateFromHTML(original.content);
      }

      return {
        ...state,
        editorState: EditorState.push(state.editorState, contentState),
        changes: {
          ...changes,
          ...(!revert && { template: action.template }),
        },
      };
    }
    case Types.BLOCK_PAGE_UPDATE_HEADER: {
      const { header, ...changes } = state.changes;
      const revert = action.header === state.original.header;

      return {
        ...state,
        changes: {
          ...changes,
          ...(!revert && { header: action.header }),
        },
      };
    }
    case Types.BLOCK_PAGE_CHECK_EDITOR_STATE: {
      const { original } = state;
      const template = hasOwn(state.changes, 'template')
        ? state.changes.template
        : state.original.template;

      if (template === 'custom') {
        const { customHTML, ...changes } = state.changes;
        const content = state.editorState
          .getCurrentContent()
          .getPlainText('\n');
        const revert = content === original.customHTML;

        return {
          ...state,
          changes: {
            ...changes,
            ...(!revert && { customHTML: content }),
          },
        };
      }

      const { content, ...changes } = state.changes;
      const editorContent = stateToHTML(state.editorState.getCurrentContent());
      const revert = editorContent === original.content;

      return {
        ...state,
        changes: {
          ...changes,
          ...(!revert && { content: editorContent }),
        },
      };
    }
    case Types.BLOCK_PAGE_UPDATE_EDITOR:
      return update(state, {
        editorState: { $set: action.editorState },
      });
    case Types.BLOCK_PAGE_SAVE_SETTINGS:
      return {
        ...state,
        saving: true,
      };
    case Types.BLOCK_PAGE_SAVE_SETTINGS_SUCCESS:
      return update(state, {
        saving: { $set: false },
        original: { $merge: state.changes },
        changes: { $set: {} },
      });
    case Types.BLOCK_PAGE_SAVE_SETTINGS_FAILURE:
      return {
        ...state,
        saving: false,
        errors: action.errors || ['Failed to save'],
      };
    case Types.BLOCK_PAGE_RESET_SETTINGS: {
      const { original } = state;
      let contentState;

      if (original.template === 'custom') {
        contentState = ContentState.createFromText(original.customHTML, '\n');
      } else {
        contentState = stateFromHTML(original.content);
      }

      return update(state, {
        changes: { $set: {} },
        editorState: {
          $set: EditorState.push(state.editorState, contentState),
        },
      });
    }
    case Types.BLOCK_PAGE_RESET_DEFAULT: {
      const { original, editorState } = state;
      const defaultSettings = state.default;
      const { header, template } = original;
      const contentState = editorState.getCurrentContent();
      let changed;
      let content;
      let editor;

      if (template === 'custom') {
        content = contentState.getPlainText('\n');
        changed = {
          ...(content !== defaultSettings.customHTML && {
            customHTML: defaultSettings.customHTML,
          }),
        };
        editor = ContentState.createFromText(defaultSettings.customHTML, '\n');
      } else {
        content = stateToHTML(contentState);
        changed = {
          ...(header !== defaultSettings.header && {
            header: defaultSettings.header,
          }),
          ...(content !== defaultSettings.content && {
            content: defaultSettings.content,
          }),
        };
        editor = stateFromHTML(defaultSettings.content);
      }

      return {
        ...state,
        changes: changed,
        ...((changed.content || changed.customHTML) && {
          editorState: EditorState.push(state.editorState, editor),
        }),
      };
    }
    default:
      return state;
  }
};

function* showPreview() {
  const blockPageSettings = yield select(getBlockPageSettings);
  const { changes, original } = blockPageSettings;
  const contentState = blockPageSettings.editorState.getCurrentContent();
  const header = hasOwn(changes, 'header') ? changes.header : original.header;
  const template = hasOwn(changes, 'template')
    ? changes.template
    : original.template;

  const tab = window.open('about:blank', '_blank');

  const content =
    template === 'custom'
      ? contentState.getPlainText('\n')
      : blockPageSettings.template
          .replace(/%header/, he.encode(header))
          .replace(/%content/, stateToHTML(contentState));

  yield tab.document.write(content);
}

function* saveSettings(action) {
  try {
    const [accountId, blockPageSettings] = yield all([
      select(getAccountId),
      select(getBlockPageSettings),
    ]);
    const { changes, original } = blockPageSettings;
    const defaultSettings = blockPageSettings.default;
    const contentState = blockPageSettings.editorState.getCurrentContent();

    const template = hasOwn(changes, 'template')
      ? changes.template
      : original.template;

    if (template === 'custom') {
      const content = contentState.getPlainText('\n');
      const result = yield call(
        Api.accounts.blockPage.upload,
        accountId,
        content
      );

      yield put(Types.saveSettingsSuccess());
      return;
    }

    const content = stateToHTML(contentState);
    const params = {
      ...(hasOwn(changes, 'header') && {
        header: changes.header,
      }),
      ...(content !== original.content && { content }),
      ...(hasOwn(changes, 'template') && {
        custom: changes.template === 'custom' ? 2 : 1,
      }),
    };

    if (
      (params.header || original.header) === defaultSettings.header &&
      (params.content || original.content) === defaultSettings.content
    ) {
      yield call(Api.accounts.blockPage.delete, accountId);
      yield put(Types.saveSettingsSuccess());
    } else {
      yield call(Api.accounts.blockPage.update, accountId, params);
      yield put(Types.saveSettingsSuccess());
    }
  } catch (e) {
    const error_messages =
      e.error.request.status === 405
        ? [e.message]
        : [e.error.request.responseText];
    yield put(Types.saveSettingsFailure(error_messages));
  }
}

function* fetchSettings() {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.blockPage.read, accountId);
    yield put(Types.fetchInfoSuccess(result));
  } catch (e) {
    yield put(Types.fetchInfoFailure(e));
  }
}

function* checkEditor() {
  yield put({
    type: Types.BLOCK_PAGE_CHECK_EDITOR_STATE,
  });
}

export function* BlockPageReducerFlow() {
  yield takeEvery(Types.BLOCK_PAGE_SHOW_PREVIEW, showPreview);
  yield takeEvery(Types.BLOCK_PAGE_FETCH_INFO, fetchSettings);
  yield takeEvery(Types.BLOCK_PAGE_SAVE_SETTINGS, saveSettings);
  yield debounce(500, Types.BLOCK_PAGE_UPDATE_EDITOR, checkEditor);
}
