import React from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';

// import StatusBanner from './StatusBanner';
import StatusBanner from '../containers/StatusBanner';
import SectionHeader from './SectionHeader';
import ComboDashboard from '../containers/ComboDashboard';
import WFDashboard from '../containers/WFDashboard';
import ATPDashboard from '../containers/ATPDashboard';
import ErrorDashboard from '../containers/ErrorDashboard';

import { PieChartIcon } from './icons';

const DashboardComponent = ({ subscriptions, msp, isMigrated }) => (
  <DocumentTitle title={translate('components.dashboard.page_title')}>
    <div>
      {!msp && <StatusBanner showButton={!isMigrated} />}
      <SectionHeader
        icon={<PieChartIcon />}
        title={translate('components.dashboard.title')}
      />
      {subscriptions.atp && subscriptions.dns && <ComboDashboard />}
      {!subscriptions.atp && (subscriptions.dns || subscriptions.wca) && (
        <WFDashboard />
      )}
      {subscriptions.atp && !subscriptions.dns && <ATPDashboard />}
      {!subscriptions.atp && !subscriptions.dns && !subscriptions.wca && (
        <ErrorDashboard />
      )}
    </div>
  </DocumentTitle>
);

DashboardComponent.propTypes = {
  subscriptions: PropTypes.object.isRequired,
  isMigrated: PropTypes.bool,
  msp: PropTypes.bool,
};

export default DashboardComponent;
