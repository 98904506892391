import { connect } from 'react-redux';
import StatusBar from '../../components/block_page/StatusBar';
import { getChangedFields } from '../../selectors/block_page_settings';
import { saveSettings, resetSettings } from '../../actions/block_page_settings';

const mapStateToProps = state => {
  const fields = getChangedFields(state);

  return {
    show: Boolean(fields.length),
    saving: state.blockPageSettings.saving,
    error: state.blockPageSettings.errors.join(', '),
  };
};

const mapDispatchToProps = dispatch => ({
  submitAction: () => {
    dispatch(saveSettings());
  },
  cancelAction: () => {
    dispatch(resetSettings());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusBar);
