import { removeUUID, constructDisplayName } from '../lib/Utils';
import { account } from '../reducers/account';

export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const ACCOUNT_CHANGE = 'ACCOUNT_CHANGE';
export const ACTIVE_DIRECTORY_LOADING = 'ACTIVE_DIRECTORY_LOADING';
export const RETRIEVE_ACCOUNT = 'RETRIEVE_ACCOUNT';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const UNSELECT_ACCOUNT = 'UNSELECT_ACCOUNT';
export const MSP_ACCOUNT_TYPE = 'MSP - Partner';
export const SMB_ACCOUNT_TYPE = 'MSP - Customer';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_TZ_SUCCESS = 'ACCOUNT_UPDATE_TZ_SUCCESS';
export const ACCOUNT_REMOVE_STATUS_BAR = 'ACCOUNT_REMOVE_STATUS_BAR';
export const GET_ACCOUNT_INSTALLER_INFO = 'GET_ACCOUNT_INSTALLER_INFO';
export const ACCOUNT_TOGGLE_SUPPORT_ACCESS = 'ACCOUNT_TOGGLE_SUPPORT_ACCESS';
export const ACCOUNT_TOGGLE_SUPPORT_ACCESS_SUCCESS =
  'ACCOUNT_TOGGLE_SUPPORT_ACCESS_SUCCESS';
export const ACCOUNT_TOGGLE_SUPPORT_ACCESS_FAILED =
  'ACCOUNT_TOGGLE_SUPPORT_ACCESS_FAILED';
export const GET_ACTIVE_DIRECTORY = 'GET_ACTIVE_DIRECTORY';
export const GET_ACTIVE_DIRECTORY_SUCCESS = 'GET_ACTIVE_DIRECTORY_SUCCESS';
export const GET_ACTIVE_DIRECTORY_FAILURE = 'GET_ACTIVE_DIRECTORY_FAILURE';
export const GET_ACCOUNTS_SUPPORT_ACCESS_SUCCESS =
  'GET_ACCOUNTS_SUPPORT_ACCESS_SUCCESS';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_INFO_SUCCESS = 'ACCOUNT_INFO_SUCCESS';
export const ACCOUNT_INFO_FAILURE = 'ACCOUNT_INFO_FAILURE';
export const DELETE_SESSION = 'DELETE_SESSION';
export const INIT_ACCOUNT_DASHBOARD = 'INIT_ACCOUNT_DASHBOARD';
export const ACCOUNT_DASHBOARD_LOADING = 'ACCOUNT_DASHBOARD_LOADING';
export const ACCOUNT_DASHBOARD_SUCCESS = 'ACCOUNT_DASHBOARD_SUCCESS';
export const ACCOUNT_DASHBOARD_FAILURE = 'ACCOUNT_DASHBOARD_FAILURE';
export const ACCOUNT_UPDATE_SUBSCRIPTIONS = 'ACCOUNT_UPDATE_SUBSCRIPTIONS';
export const ACCOUNT_SAVE_SUBSCRIPTIONS = 'ACCOUNT_SAVE_SUBSCRIPTIONS';
export const STORE_USER_NAME = 'STORE_USER_NAME';
export const STORE_SERIALS = 'STORE_SERIALS';
export const SHOW_SESSION_MODAL = 'SHOW_SESSION_MODAL';
export const CLOSE_SESSION_MODAL = 'CLOSE_SESSION_MODAL';
export const REFRESH_SESSION = 'REFRESH_SESSION';
export const SUCCESS_REFRESH_TOKEN = 'SUCCESS_REFRESH_TOKEN';
export const FAILED_REFRESH_TOKEN = 'FAILED_REFRESH_TOKEN';

export const GET_ACCOUNT_SUBSCRIPTION = 'GET_ACCOUNT_SUBSCRIPTION';

export const ACCOUNT_DOWNLOAD_CONFIG = 'ACCOUNT_DOWNLOAD_CONFIG';
export const ACCOUNT_DOWNLOAD_CONFIG_SUCCESS =
  'ACCOUNT_DOWNLOAD_CONFIG_SUCCESS';
export const ACCOUNT_DOWNLOAD_CONFIG_FAILURE =
  'ACCOUNT_DOWNLOAD_CONFIG_FAILURE';

export const CHROMEBOOK_DOWNLOAD_CONFIG = 'CHROMEBOOK_DOWNLOAD_CONFIG';
export const CHROMEBOOK_DOWNLOAD_CONFIG_SUCCESS =
  'CHROMEBOOK_DOWNLOAD_CONFIG_SUCCESS';
export const CHROMEBOOK_DOWNLOAD_CONFIG_FAILURE =
  'CHROMEBOOK_DOWNLOAD_CONFIG_FAILURE';

export const MSP_TOGGLE_SUPPORT_TUNNEL = 'MSP_TOGGLE_SUPPORT_TUNNEL';
export const MSP_TOGGLE_SUPPORT_SUCCESS = 'MSP_TOGGLE_SUPPORT_SUCCESS';
export const MSP_TOGGLE_SUPPORT_FAILED = 'MSP_TOGGLE_SUPPORT_FAILED';

export const ONBOARD_TRIAL_ACCOUNT = 'ONBOARD_TRIAL_ACCOUNT';
export const ONBOARD_ERROR = 'ONBOARD_ERROR';
export const ONBOARD_SUCCESS = 'ONBOARD_SUCCESS';
export const REDIRECT_LOGIN = 'REDIRECT_LOGIN';
export const REDIRECT_SECURE_EDGE = 'REDIRECT_SECURE_EDGE';
export const GENERATE_MIGRATE_LINK = 'GENERATE_MIGRATE_LINK';
export const GENERATE_MIGRATE_LINK_SUCCESS = 'GENERATE_MIGRATE_LINK_SUCCESS';
export const GENERATE_MIGRATE_LINK_FAILURE = 'GENERATE_MIGRATE_LINK_FAILURE';
export const GENERATE_MSP_MIGRATE_LINK_SUCCESS =
  'GENERATE_MSP_MIGRATE_LINK_SUCCESS';
export const GENERATE_MSP_MIGRATE_LINK_FAILURE =
  'GENERATE_MSP_MIGRATE_LINK_FAILURE';

export const MERGE_ACTIVE_DIRECTORY = 'MERGE_ACTIVE_DIRECTORY';

export const onboard_trial = () => ({
  type: ONBOARD_TRIAL_ACCOUNT,
});

export const onBoardError = () => ({
  type: ONBOARD_ERROR,
});

export const onBoardSuccess = () => ({
  type: ONBOARD_SUCCESS,
});

export const redirectLogin = () => ({
  type: REDIRECT_LOGIN,
});

export const redirectSecureEdge = () => ({
  type: REDIRECT_SECURE_EDGE,
});

export const generateMigrateLink = account_id => ({
  type: GENERATE_MIGRATE_LINK,
  account_id,
});

export const generateMigrateLinkSuccess = result => {
  return {
    type: GENERATE_MIGRATE_LINK_SUCCESS,
    result,
  };
};

export const generateMigrateLinkFailure = error => ({
  type: GENERATE_MIGRATE_LINK_FAILURE,
  error,
});

export const generateMspMigrateLinkSuccess = result => {
  return {
    type: GENERATE_MSP_MIGRATE_LINK_SUCCESS,
    result,
  };
};

export const generateMspMigrateLinkFailure = error => ({
  type: GENERATE_MSP_MIGRATE_LINK_FAILURE,
  error,
});

export const mspToggleSupportTunnel = supportEnabled => ({
  type: MSP_TOGGLE_SUPPORT_TUNNEL,
  supportEnabled,
});

export const toggleMSPSupportSuccess = result => ({
  type: MSP_TOGGLE_SUPPORT_SUCCESS,
  result,
});

export const toggleMSPSupportFailed = error => ({
  type: MSP_TOGGLE_SUPPORT_FAILED,
  error,
});

export const getSubscription = (useCache = false) => ({
  type: GET_ACCOUNT_SUBSCRIPTION,
  useCache,
});

export const storeUserName = userName => ({
  type: STORE_USER_NAME,
  userName,
});

export const downloadAccountConfig = () => ({
  type: ACCOUNT_DOWNLOAD_CONFIG,
});

export const downloadAccountConfigSuccess = () => ({
  type: ACCOUNT_DOWNLOAD_CONFIG_SUCCESS,
});

export const downloadAccountConfigFailure = error => ({
  type: ACCOUNT_DOWNLOAD_CONFIG_FAILURE,
  error,
});
export const downloadChromebookConfig = () => ({
  type: CHROMEBOOK_DOWNLOAD_CONFIG,
});
export const downloadChromebookConfigSuccess = () => ({
  type: CHROMEBOOK_DOWNLOAD_CONFIG_SUCCESS,
});
export const downloadChromebookConfigFailure = error => ({
  type: CHROMEBOOK_DOWNLOAD_CONFIG_FAILURE,
  error,
});
export const getActiveDirectory = () => ({
  type: GET_ACTIVE_DIRECTORY,
});

export const initAccountDashboard = () => ({
  type: INIT_ACCOUNT_DASHBOARD,
});

export const accountDashboardLoading = () => ({
  type: ACCOUNT_DASHBOARD_LOADING,
});

export const activeDirectoryLoading = () => ({
  type: ACTIVE_DIRECTORY_LOADING,
});

export const getAccountDataSuccess = result => {
  return {
    type: ACCOUNT_DASHBOARD_SUCCESS,
    mspSubStats: Object.keys(result.msp).map(key => ({
      ...result.msp[key],
      type: key,
    })),
    result,
  };
};

export const getAccountDataFailure = e => ({
  type: ACCOUNT_DASHBOARD_FAILURE,
  e,
});

export const formatUsers = (userHash, userOptions, result) => {
  return result['users']
    .map(u => {
      userHash[u.uuid || u.id] = u.display_name || u.name;
      userOptions.concat({
        type: 'user',
        label: constructDisplayName(u.display_name || u.name, u.dir_name),
        key: u.uuid || u.id,
        value: u.uuid || u.id,
      });
      return {
        ...u,
        user_id: u.user_id || u.id,
        display_name: constructDisplayName(
          u.display_name || u.name,
          u.dir_name
        ),
        uuid: u.uuid || u.id,
        label: constructDisplayName(u.display_name || u.name, u.dir_name),
        key: u.uuid || u.id,
        value: u.uuid || u.id,
        [u.uuid || u.id]: u.display_name || u.name,
        type: 'user',
      };
    })
    .sort((a, b) => (a.label || '').localeCompare(b.label));
};

export const formatGroups = result => {
  if (!result['groups']) {
    return [];
  }

  let groups = result['groups']
    .map(g => ({
      ...g,
      group_id: g.group_id || g.id,
      group_name: constructDisplayName(
        removeUUID(g.group_name) || g.name,
        g.dir_name
      ),
      uuid: g.uuid || g.id,
      label: constructDisplayName(
        removeUUID(g.group_name) || g.name,
        g.dir_name
      ),
      value: g.uuid || g.id,
      key: g.uuid || g.id,
      [g.uuid || g.id]: removeUUID(g.group_name) || g.name,
      type: 'group',
    }))
    .sort((a, b) => (a.label || '').localeCompare(b.label));

  return groups;
};

const _flattenAD = result => {
  let userHash = {};
  let userOptions = [];
  let users = formatUsers(userHash, userOptions, result);
  let groups = formatGroups(result);

  let flatAD = [];
  flatAD = flatAD.concat(users);
  flatAD = flatAD.concat(groups);
  const adMap = flatAD.reduce((map, obj) => {
    map[obj.uuid] = obj;
    return map;
  }, {});

  return {
    flatAD,
    adMap,
    users,
    groups,
    userHash,
    userOptions,
  };
};

export const getActiveDirectorySuccess = result => {
  const formatData = _flattenAD(result);
  return {
    type: GET_ACTIVE_DIRECTORY_SUCCESS,
    ...formatData,
  };
};

export const mergeActiveDirectory = result => {
  const formatData = _flattenAD(result);
  return {
    type: MERGE_ACTIVE_DIRECTORY,
    ...formatData,
  };
};

export const getActiveDirectoryFailure = e => ({
  type: GET_ACTIVE_DIRECTORY_FAILURE,
  e,
});

export const getAccountInstallers = account => ({
  type: GET_ACCOUNT_INSTALLER_INFO,
  account,
});

export const accountLoading = () => ({
  type: ACCOUNT_LOADING,
});

export const getAccountInfoSuccess = installers => ({
  type: ACCOUNT_INFO_SUCCESS,
  installers,
});

export const getAccountInfoFailure = e => ({
  type: ACCOUNT_INFO_FAILURE,
  e,
});

export const getSupportAccessSuccess = supportAccess => ({
  type: GET_ACCOUNTS_SUPPORT_ACCESS_SUCCESS,
  supportAccess,
});

export const changeAccount = name => ({
  type: ACCOUNT_CHANGE,
  account_name: name,
});

export const retrieveAccount = (
  email,
  id,
  accounts,
  services,
  user_id,
  time_zone = 'America/Los_Angeles'
) => ({
  type: RETRIEVE_ACCOUNT,
  email,
  id,
  accounts,
  services,
  user_id,
  time_zone: time_zone || 'America/Los_Angeles',
});

const subBitmap = {
  atp: 1,
  dns: 2,
  wca: 4,
  account: 5,
};
const subKeys = Object.keys(subBitmap);

export const updateAccountInfo = accountInfo => ({
  type: UPDATE_ACCOUNT_INFO,
  accountInfo,
});

export const updateSubscriptions = (subs = []) => ({
  type: ACCOUNT_UPDATE_SUBSCRIPTIONS,
  subs: subKeys.filter(sub => subBitmap[sub] & subs), // eslint-disable-line no-bitwise
});

export const storeSubscriptions = (subs, accountSubs) => {
  const parsedAccountSubs = {};

  accountSubs.forEach((accounts, bitmap) => {
    accounts.forEach(accountId => {
      parsedAccountSubs[accountId] = bitmap;
    });
  });

  return {
    type: ACCOUNT_SAVE_SUBSCRIPTIONS,
    mspSubs: subs,
    accountSubs: parsedAccountSubs,
  };
};

export const storeSerials = serials => ({
  type: STORE_SERIALS,
  serials,
});

export const selectAccount = (id, name) => ({
  type: SELECT_ACCOUNT,
  id,
  name,
});

export const unselectAccount = () => ({
  type: UNSELECT_ACCOUNT,
});

export const changeTimezone = value => ({
  type: CHANGE_TIMEZONE,
  value,
  field: 'timezone',
});

export const changePassword = value => ({
  type: CHANGE_PASSWORD,
  value,
  field: 'password',
});

export const accountUpdateSuccess = field => ({
  type: ACCOUNT_UPDATE_SUCCESS,
  field,
});

export const accountUpdateTZSuccess = time_zone => ({
  type: ACCOUNT_UPDATE_TZ_SUCCESS,
  time_zone,
});

export const accountUpdateFailure = result => ({
  type: ACCOUNT_UPDATE_FAILURE,
  result,
});

export const removeStatusBar = result => ({
  type: ACCOUNT_REMOVE_STATUS_BAR,
  result,
});

export const toggleSupport = account_id => ({
  type: ACCOUNT_TOGGLE_SUPPORT_ACCESS,
  account_id,
});

export const toggleSupportSuccess = account_id => ({
  type: ACCOUNT_TOGGLE_SUPPORT_ACCESS_SUCCESS,
  account_id,
});

export const toggleSupportFailed = account_id => ({
  type: ACCOUNT_TOGGLE_SUPPORT_ACCESS_FAILED,
  account_id,
});

export const deleteSession = token => ({
  type: DELETE_SESSION,
  token,
});

export const ACCOUNT_GET_INSTALLERS = 'ACCOUNT_GET_INSTALLERS';
export const getInstallers = () => ({
  type: ACCOUNT_GET_INSTALLERS,
});
export const ACCOUNT_GET_INSTALLERS_SUCCESS = 'ACCOUNT_GET_INSTALLERS_SUCCESS';
export const ACCOUNT_GET_INSTALLERS_FAILURE = 'ACCOUNT_GET_INSTALLERS_FAILURE';

export const SHOW_INSTALLER_RELEASE_NOTES = 'SHOW_INSTALLER_RELEASE_NOTES';
export const showReleaseNotes = version => ({
  type: SHOW_INSTALLER_RELEASE_NOTES,
  version,
});
export const CLOSE_INSTALLER_RELEASE_NOTES = 'CLOSE_INSTALLER_RELEASE_NOTES';
export const closeReleaseNotes = () => ({
  type: CLOSE_INSTALLER_RELEASE_NOTES,
});

export const SHOW_INSTALLER_RELEASE_NOTES_SUCCESS =
  'SHOW_INSTALLER_RELEASE_NOTES_SUCCESS';

export const showSessionModal = () => ({
  type: SHOW_SESSION_MODAL,
});

export const closeSessionModal = () => ({
  type: CLOSE_SESSION_MODAL,
});

export const refreshSession = () => ({
  type: REFRESH_SESSION,
});

export const successRefresh = () => ({
  type: SUCCESS_REFRESH_TOKEN,
});

export const failedRefresh = () => ({
  type: FAILED_REFRESH_TOKEN,
});
