import axios from 'axios';
import { translate } from 'react-i18nify';
import Auth from '../lib/Auth';
import { TOKEN_URL, COOKIE_VALIDATOR_URL } from '../lib/Env';
import {
  BASE_URL,
  STAGE,
  DOWNLOAD_URL,
  PDF_URL,
  SECUREEDGE_URL,
} from '../lib/Env';
import { clearError } from '../actions/app';
import { saveAs } from 'file-saver/FileSaver';

const cleanAxios = axios.create();
const demoAccounts = [1004808008, 11052550, 5584599];

export const setupAxiosHandler = (store, history) => {
  axios.interceptors.request.use(function(config) {
    const state = store.getState();
    store.dispatch(clearError());
    config.headers.common.Authorization = Auth.get_token();
    config.headers.common.wcsversion =
      state.basic_filtering.catMapping.wcsVersion || '3.0';
    if (state.account && state.account.selected) {
      config.headers.common.Account = state.account.selected;
    } else if (state.activate.info.accountId) {
      config.headers.common.Account = state.activate.info.accountId;
    }
    return config;
  });

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && !(error.config.url.indexOf('/activate') > -1)) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 403) {
          Auth.logout();
          window.location = '/login/401';
        } else if (error.response.status === 401) {
          Auth.clear_refresh_interval();
          const state = store.getState();
          if (demoAccounts.includes(Number(state.account.selected))) {
            return Promise.resolve({
              status: 200,
              statusText: 'BCS Demo',
              data: {},
            });
          } else {
            Auth.logout();
            window.location = '/login/401';
          }
        }
        return Promise.reject({ message: error.response.data.message, error });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({ message: translate('errors.503'), error });
      }

      // Something happened in setting up the request that triggered an Error
      return Promise.reject({ message: translate('errors.app'), error });
    }
  );
};

const tokenAxios = axios.create();

const blockPage = {
  create: (account, params) =>
    axios
      .post(`${BASE_URL}/accounts/${account}/block_page`, params)
      .then(res => res.data),
  read: account =>
    axios
      .get(`${BASE_URL}/accounts/${account}/block_page`)
      .then(res => res.data),
  update: (account, params) =>
    axios
      .patch(`${BASE_URL}/accounts/${account}/block_page`, params)
      .then(res => res.data),
  delete: account =>
    axios
      .delete(`${BASE_URL}/accounts/${account}/block_page`)
      .then(res => res.data),
  upload: (account, content) =>
    axios
      .post(`${BASE_URL}/accounts/${account}/uploads`, { block_page: true })
      .then(res => {
        const presigned = res.data.block_page;
        const formData = new FormData(); // eslint-disable-line

        Object.keys(presigned.fields).forEach(field => {
          formData.append(field, presigned.fields[field]);
        });
        formData.set('Content-Type', 'text/html');
        formData.append(
          'file',
          new Blob([content], {
            type: 'text/html',
          })
        );

        return cleanAxios.post(presigned.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }),
};

const syslogConfig = {
  read: account =>
    axios.get(`${BASE_URL}/accounts/${account}/syslog`).then(res => res.data),
  update: (account, params) =>
    axios
      .patch(`${BASE_URL}/accounts/${account}/syslog`, params)
      .then(res => res.data),
  delete: account =>
    axios
      .delete(`${BASE_URL}/accounts/${account}/syslog`)
      .then(res => res.data),
};

const localGroups = {
  create: (account, params) =>
    axios
      .post(`${BASE_URL}/accounts/${account}/groups`, params)
      .then(res => res.data),
  read: account =>
    axios.get(`${BASE_URL}/accounts/${account}/groups`).then(res => res.data),
  update: (account, group, params) =>
    axios
      .patch(`${BASE_URL}/accounts/${account}/groups/${group}`, params)
      .then(res => res.data),
  delete: (account, group) =>
    axios
      .delete(`${BASE_URL}/accounts/${account}/groups/${group}`)
      .then(res => res.data),
  clearall: account =>
    axios
      .delete(`${BASE_URL}/accounts/${account}/groups`)
      .then(res => res.datta),
  addUsers: (account, group, params) =>
    axios
      .post(`${BASE_URL}/accounts/${account}/groups/${group}`, params)
      .then(res => res.data),
  removeUsers: (account, group, user) =>
    axios
      .delete(`${BASE_URL}/accounts/${account}/groups/${group}/users/${user}`)
      .then(res => res.data),
};

const sharedPolicies = {
  create: params => axios.post(`${BASE_URL}/shared_policies`, params),
  update: (id, params) =>
    axios.put(`${BASE_URL}/shared_policies/${id}`, params),
  read: params => axios.get(`${BASE_URL}/shared_policies`),
  get: id => axios.get(`${BASE_URL}/shared_policies/${id}`),
  remove: id => axios.delete(`${BASE_URL}/shared_policies/${id}`),
  removeAccount: (id, account_id) =>
    axios.delete(`${BASE_URL}/shared_policies/${id}/accounts/${account_id}`),
};

const localUsers = {
  clearall: account =>
    axios.delete(`${BASE_URL}/accounts/${account}/users`).then(res => res.data),
  create: (account, params) =>
    axios
      .post(`${BASE_URL}/accounts/${account}/users`, params)
      .then(res => res.data),
  read: account =>
    axios.get(`${BASE_URL}/accounts/${account}/users`).then(res => res.data),
  update: (account, user, params) =>
    axios
      .patch(`${BASE_URL}/accounts/${account}/users/${user}`, params)
      .then(res => res.data),
  userData: params =>
    axios
      .get(BASE_URL + '/data', { params })
      .then(res => res.data)
      .catch(error => []),
};

const csv = {
  generate: (account, params) =>
    axios.post(`${BASE_URL}/logs/${account}/csv`, params).then(res => res.data),
  checkJob: (account, job) =>
    axios
      .get(`${BASE_URL}/logs/${account}/csv/${job}`)
      .then(res => res.data)
      .catch(error => ({ status: 'PENDING' })),
  download: (presignedURL, fileName) =>
    cleanAxios
      .get(presignedURL, {
        responseType: 'blob',
      })
      .then(response => {
        saveAs(
          new Blob([response.data], {
            type: 'application/octet-stream;charset=utf-8',
          }),
          fileName
        );
        return '';
      }),
};

const Api = {
  csv,
  auth: {
    refresh: token =>
      tokenAxios
        .put(TOKEN_URL, {
          refresh_token: token,
        })
        .then(res => res.data),
    cookie_refresh: () =>
      window
        .fetch(COOKIE_VALIDATOR_URL, {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          body: JSON.stringify({}),
        })
        .then(response => {
          return response.json();
        }),
  },
  directory: {
    read: params => {
      return axios
        .get(BASE_URL + '/accounts/' + params['account_id'] + '/directory')
        .then(res => res.data);
    },
    readUsers: params => {
      let config = {};

      if ('query' in params) {
        config = {
          params: { query: params['query'] },
        };
      }

      if ('ids' in params) {
        config = {
          params: { ids: params['ids'] },
        };
      }

      return axios
        .get(
          BASE_URL + '/accounts/' + params['account_id'] + '/directory/users',
          config
        )
        .then(res => res.data);
    },
    readGroups: params => {
      let config = {};

      if ('query' in params) {
        config = {
          params: { query: params['query'] },
        };
      }

      if ('ids' in params) {
        config = {
          params: { ids: params['ids'] },
        };
      }

      if ('user_ids' in params) {
        config = {
          params: { user_ids: params['user_ids'] },
        };
      }

      return axios
        .get(
          BASE_URL + '/accounts/' + params['account_id'] + '/directory/groups',
          config
        )
        .then(res => res.data);
    },
  },
  getATP: () => {
    return axios.get(BASE_URL + '/atp').then(res => res.data);
  },
  updateATP: atp => {
    return axios.put(BASE_URL + '/atp', { ...atp }).then(res => res.data);
  },
  scheduleATP: atp => {
    return axios
      .put(BASE_URL + '/atp_schedule_scan', { ...atp })
      .then(res => res.data);
  },
  getLogs: params => {
    return axios.get(BASE_URL + '/logs', { params }).then(res => res.data);
  },
  getData: params => {
    return axios.get(BASE_URL + '/data', { params }).then(res => res.data);
  },
  getDeviceList: params => {
    return axios.get(BASE_URL + '/data', { params }).then(res => res.data);
  },
  iotCommand: params => {
    return axios
      .put(BASE_URL + '/iot_support', { ...params })
      .then(res => res.data);
  },
  agentInstallerURL: params =>
    axios.get(`${DOWNLOAD_URL}/installer`, { params }).then(res => res.data),
  downloadAccountConfig: params => {
    return axios
      .get(DOWNLOAD_URL + '/account_config', {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        },
        params: {
          ...params,
          auth: Auth.get_token(),
        },
      })
      .then(response => {
        saveAs(
          new Blob([response.data], {
            type: 'application/octet-stream;charset=utf-8',
          }),
          'bcs.key'
        );
        return '';
      });
  },
  downloadChromebookConfig: params => {
    return axios
      .get(DOWNLOAD_URL + '/chromebook_config', {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
        },
        params: {
          ...params,
          auth: Auth.get_token(),
        },
      })
      .then(response => {
        saveAs(
          new Blob([response.data], {
            type: 'application/json;charset=utf-8',
          }),
          'bcs_chromebook.txt'
        );
        return '';
      });
  },
  downloadReport: params => {
    return axios
      .get(PDF_URL, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
        params: {
          ...params,
          auth: Auth.get_token(),
        },
      })
      .then(response => {
        saveAs(
          new Blob([response.data], { type: 'application/pdf;charset=utf-8' }),
          'report.pdf'
        );
        return '';
      });
  },
  redirectSecureEdge: params => {
    //
    // Possible implementation of redirect to secure edge. Currect not working due to preflight CORS.
    // Will need to either implement a proxy server, have secure edge allow to check or use a different method to redirect to secure edge.
    //
    return axios
      .post(SECUREEDGE_URL, { config_url: 'someURL' })
      .then(res => res.data);
  },
  generateMigrateLink: params => {
    return axios
      .get(`${BASE_URL}/migrate_secure_edge`, {params})
      .then(res => res.data);
  },
  session: {
    delete: token => {
      return axios
        .delete(BASE_URL + '/token', {
          headers: {
            Authorization: token,
          },
        })
        .then(res => res.data);
    },
  },
  notifications: {
    create: (params, account) => {
      return axios
        .post(BASE_URL + '/notifications', params)
        .then(res => res.data);
    },
    read: () => {
      return axios.get(BASE_URL + '/notifications').then(res => res.data);
    },
    update: (id, params) => {
      return axios
        .patch(BASE_URL + '/notifications/' + id, params)
        .then(res => res.data);
    },
    delete: id => {
      return axios
        .delete(BASE_URL + '/notifications/' + id)
        .then(res => res.data);
    },
  },
  scheduleReports: {
    runReports: params => {
      return axios
        .post(BASE_URL + '/schedule_reports', params)
        .then(res => res.data);
    },
    read: () => {
      return axios.get(BASE_URL + '/schedule_reports').then(res => res.data);
    },
    create: params => {
      return axios
        .post(BASE_URL + '/schedule_reports', params)
        .then(res => res.data);
    },
    replace: params => {
      return axios
        .post(BASE_URL + '/schedule_reports', params)
        .then(res => res.data);
    },
    delete: (id, params) => {
      return axios
        .post(BASE_URL + '/schedule_reports', params)
        .then(res => res.data);
    },
  },
  quarantine: {
    read: params => {
      return axios
        .get(BASE_URL + '/quarantine', { params })
        .then(res => res.data);
    },
    resolve: files => {
      return axios.patch(BASE_URL + '/commands', files).then(res => res.data);
    },
  },
  locationPolicy: {
    create: params => {
      return axios
        .post(BASE_URL + '/location_policy', params)
        .then(res => res.data);
    },
    read: params => {
      return axios
        .get(BASE_URL + '/location_policy', params)
        .then(res => res.data);
    },
    replace: params => {
      return axios
        .put(BASE_URL + '/location_policy', params)
        .then(res => res.data);
    },
    delete: params => {
      return axios
        .delete(BASE_URL + '/location_policy', params)
        .then(res => res.data);
    },
  },
  rules: {
    create: params => {
      return axios
        .post(
          BASE_URL +
            '/accounts/' +
            params['account_id'] +
            '/rulesets/' +
            params['ruleset_id'] +
            '/rules',
          params
        )
        .then(res => res.data);
    },
    read: params => {
      return axios
        .get(
          BASE_URL +
            '/accounts/' +
            params['account_id'] +
            '/rulesets/' +
            params['ruleset_id'] +
            '/rules'
        )
        .then(res => res.data);
    },
    update: (ids, params) => {
      return axios
        .patch(
          BASE_URL +
            '/accounts/' +
            ids['account_id'] +
            '/rulesets/' +
            ids['ruleset_id'] +
            '/rules/' +
            ids['rule_id'],
          params
        )
        .then(res => res.data);
    },
    delete: params => {
      return axios
        .delete(
          BASE_URL +
            '/accounts/' +
            params['account_id'] +
            '/rulesets/' +
            params['ruleset_id'] +
            '/rules/' +
            params['rule_id']
        )
        .then(res => res.data);
    },
  },
  ruleSets: {
    create: params => {
      return axios
        .post(BASE_URL + '/accounts/' + params['account_id'] + '/rulesets', {
          main: false,
        })
        .then(res => res.data);
    },
    read: params => {
      return axios
        .get(BASE_URL + '/accounts/' + params['account_id'] + '/rulesets')
        .then(res => res.data);
    },
    delete: params => {
      return axios
        .delete(
          BASE_URL +
            '/accounts/' +
            params['account_id'] +
            '/rulesets/' +
            params['id']
        )
        .then(res => res.data);
    },
    users: {
      read: params => {
        return axios
          .get(
            BASE_URL +
              '/accounts/' +
              params['account_id'] +
              '/users/' +
              params['user_id'] +
              '/rulesets'
          )
          .then(res => res.data);
      },
    },
    groups: {
      read: params => {
        return axios
          .get(
            BASE_URL +
              '/accounts/' +
              params['account_id'] +
              '/groups/' +
              params['group_id'] +
              '/rulesets'
          )
          .then(res => res.data);
      },
    },
  },
  users: {
    verify_email: email =>
      axios
        .post(`${BASE_URL}/users`, { email })
        .then(res => res.data)
        .catch(error => ({ error: true, status: error.error.response.status })),
    create: params =>
      axios.post(`${BASE_URL}/users`, params).then(
        res => ({
          status: res.status,
        }),
        error => ({
          status: error.error.response.status,
          message: error.message,
        })
      ),
    update: (user_id, params) => {
      return axios
        .patch(BASE_URL + '/users/' + user_id, params)
        .then(res => res.data);
    },
  },
  installers: {
    installer_info: version =>
      axios.get(`${BASE_URL}/installers/${version}`).then(res => res.data),
  },
  reportFalsePositive: params =>
    axios
      .put(`${BASE_URL}/report_false_positive`, params)
      .then(res => res.data),
  modifiers: {
    get: account =>
      axios
        .get(`${BASE_URL}/accounts/${account}/modifiers`)
        .then(res => res.data),
    create: (accountId, params) =>
      axios
        .post(`${BASE_URL}/accounts/${accountId}/modifiers`, params)
        .then(res => res.data)
        .catch(error => ({ error: true, status: error.error.response.status })),
    update: (accountId, id, params) =>
      axios
        .put(`${BASE_URL}/accounts/${accountId}/modifiers/${id}`, params)
        .then(res => res.data)
        .catch(error => ({ error: true, status: error.error.response.status })),
    remove: (accountId, id) =>
      axios
        .delete(`${BASE_URL}/accounts/${accountId}/modifiers/${id}`)
        .then(res => res.data),
  },
  accounts: {
    metrics: account =>
      axios
        .get(`${BASE_URL}/accounts/${account}/metrics`)
        .then(res => res.data),
    progress: account =>
      axios
        .get(`${BASE_URL}/accounts/${account}/progress`)
        .then(res => res.data),
    policy_settings: account =>
      axios
        .get(`${BASE_URL}/accounts/${account}/policy_settings`)
        .then(res => res.data),
    policy_settings_update: (account, params) =>
      axios
        .patch(`${BASE_URL}/accounts/${account}/policy_settings`, params)
        .then(res => res.data),
    policy_settings: account =>
      axios
        .get(`${BASE_URL}/accounts/${account}/policy_settings`)
        .then(res => res.data),
    synctool: accountId =>
      axios
        .get(`${BASE_URL}/accounts/${accountId}/synctool`)
        .then(res => res.data),
    installers: accountId =>
      axios
        .get(`${BASE_URL}/accounts/${accountId}/installers`)
        .then(res => res.data),
    activateTrial: (accountId, params) =>
      axios
        .post(`${BASE_URL}/accounts/${accountId}/licenses`, params)
        .then(res => res.data)
        .catch(error => ({ error: true, status: error.error.response.status })),
    activate: (account, params) =>
      axios
        .post(`${BASE_URL}/activate/${account}`, params, {
          timeout: 25000,
        })
        .then(
          res => res.data,
          error => {
            if (error.error.code === 'ECONNABORTED') {
              return {
                timeout: true,
              };
            }
            throw error;
          }
        ),
    subscriptions: (account, params) =>
      axios.put(`${BASE_URL}/activate/${account}`, params),
    getAll: () => axios.get(`${BASE_URL}/accounts`).then(res => res.data),
    updateAll: params =>
      axios.patch(`${BASE_URL}/accounts`, params).then(res => res.data),
    read: accountId =>
      axios.get(`${BASE_URL}/accounts/${accountId}`).then(res => res.data),
    getSettings: accountId =>
      axios
        .get(`${BASE_URL}/accounts/${accountId}/settings`)
        .then(res => res.data),
    update: (accountId, params) =>
      axios
        .patch(`${BASE_URL}/accounts/${accountId}`, params)
        .then(res => res.data),
    getSyslog: accountId =>
      axios.get(`${BASE_URL}/accounts/${accountId}`).then(res => res.data),
    syslogConfig,
    sharedPolicies,
    blockPage,
    localUsers,
    localGroups,
    logo: url => cleanAxios.get(url).then(response => response.headers.etag),
    upload: (account, params) =>
      axios
        .post(`${BASE_URL}/accounts/${account}/uploads`, { logo: true })
        .then(res => {
          const presigned = res.data.logo;
          const formData = new FormData(); // eslint-disable-line

          Object.keys(presigned.fields).forEach(field => {
            formData.append(field, presigned.fields[field]);
          });
          formData.set('Content-Type', params.logo.file.type);
          formData.append('file', params.logo.file);

          return cleanAxios.post(presigned.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        })
        .then(response => {
          const { etag } = response.headers;
          return etag;
          // return blockPage.update(account, { custom: true }).then(() => etag);
        }),
  },
  recategorizeDomain: params => {
    axios.put(`${BASE_URL}/recategorize_domain`, params).then(res => res.data);
  },
};

export default Api;
