import update from 'immutability-helper';
import {
  all,
  put,
  delay,
  takeLatest,
  takeEvery,
  select,
  call,
} from 'redux-saga/effects';

import * as Types from '../actions/user_authentication';
import * as AppTypes from '../actions/app';
import {
  getUserAuthentication,
  getGroupList,
} from '../selectors/user_authentication';
import { getAccountId } from '../selectors/account';

import Api from './Api';

const hasOwn = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

const defaultModal = {
  name: '',
  show: false,
  data: {},
  changes: {},
  processing: false,
  error: '',
};

export default (
  state = {
    userType: 'directory',
    directoryConfigured: false,
    query: '',
    loading: false,
    account: {
      pending: false,
      error: false,
    },
    modal: {
      ...defaultModal,
    },
    sort: {
      field: 'name',
      direction: 'desc',
    },
    expanded: {},
    users: [],
    groups: [],
    synctool: {
      exe: '',
      msi: '',
    },
  },
  action
) => {
  switch (action.type) {
    case Types.USER_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.USER_AUTH_ACCOUNT_STATUS:
      return {
        ...state,
        account: {
          pending: action.statuses.pending,
          error: action.statuses.error,
        },
      };
    case Types.USER_AUTH_UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case Types.USER_AUTH_CHANGE_SORT: {
      let direction = 'desc';

      if (
        action.field === state.sort.field &&
        state.sort.direction === 'desc'
      ) {
        direction = 'asc';
      }

      return {
        ...state,
        sort: {
          field: action.field,
          direction,
        },
      };
    }
    case Types.USER_AUTH_OPEN_MODAL:
      return update(state, {
        modal: {
          $set: {
            ...defaultModal,
            name: action.modal,
            show: true,
            data: action.data,
          },
        },
      });
    case Types.USER_AUTH_CLOSE_MODAL:
      return update(state, {
        modal: {
          $merge: {
            show: false,
          },
        },
      });
    case Types.USER_AUTH_UPDATE_MODAL_DATA: {
      const { field, value } = action;
      const { data } = state.modal;

      if (data[field] === value) {
        return update(state, {
          modal: {
            changes: {
              $unset: [field],
            },
          },
        });
      }

      return update(state, {
        modal: {
          changes: {
            $merge: {
              [field]: value,
            },
          },
        },
      });
    }
    case Types.USER_AUTH_TOGGLE_GROUP_EXPAND: {
      const { id } = action;

      return update(state, {
        expanded: {
          [id]: {
            $set: !state.expanded[id],
          },
        },
      });
    }
    case Types.USER_AUTH_HIDE_MODAL:
      return update(state, {
        modal: {
          show: {
            $set: false,
          },
        },
      });
    case Types.USER_AUTH_UPDATE_USER:
    case Types.USER_AUTH_ADD_USERS:
    case Types.USER_AUTH_UPDATE_GROUP:
    case Types.USER_AUTH_MOVE_USER:
    case Types.USER_AUTH_REMOVE_FROM_GROUP:
    case Types.USER_AUTH_DELETE_GROUP:
    case Types.USER_AUTH_CHANGE_TYPE:
      return update(state, {
        modal: {
          processing: {
            $set: true,
          },
        },
      });
    case Types.USER_AUTH_UPDATE_USER_FAILURE:
    case Types.USER_AUTH_ADD_USERS_FAILURE:
    case Types.USER_AUTH_UPDATE_GROUP_FAILURE:
    case Types.USER_AUTH_MOVE_USER_FAILURE:
    case Types.USER_AUTH_REMOVE_FROM_GROUP_FAILURE:
    case Types.USER_AUTH_DELETE_GROUP_FAILURE:
    case Types.USER_AUTH_CHANGE_TYPE_FAILURE:
      return update(state, {
        modal: {
          error: {
            $set: action.error || 'Failed',
          },
          processing: {
            $set: false,
          },
        },
      });
    case Types.USER_AUTH_UPDATE_USER_SUCCESS:
      return update(state, {
        users: {
          [action.user.id]: { $merge: action.user },
        },
      });
    case Types.USER_AUTH_UPDATE_GROUP_SUCCESS:
      return update(state, {
        groups: {
          [action.group.id]: { $merge: action.group },
        },
      });
    case Types.USER_AUTH_MOVE_USER_SUCCESS: {
      const newUsers =
        state.groups[action.newGroup].users.indexOf(action.user) === -1
          ? [action.user]
          : [];
      return update(state, {
        groups: {
          [action.oldGroup]: {
            users: {
              $apply: users => users.filter(user => user !== action.user),
            },
          },
          [action.newGroup]: {
            users: { $push: newUsers },
          },
        },
      });
    }
    case Types.USER_AUTH_REMOVE_FROM_GROUP_SUCCESS:
      return update(state, {
        groups: {
          [action.group]: {
            users: {
              $apply: users => users.filter(user => user !== action.user),
            },
          },
        },
      });
    case Types.USER_AUTH_CREATE_GROUP_SUCCESS:
      return update(state, {
        groups: {
          $merge: {
            [action.group.id]: action.group,
          },
        },
      });
    case Types.USER_AUTH_ADD_USERS_SUCCESS: {
      const { users, group } = action;
      const currentUsers = state.groups[group.id]
        ? state.groups[group.id].users
        : [];

      return update(state, {
        groups: {
          [group.id]: {
            users: {
              $set: [
                ...new Set([...currentUsers, ...users.map(user => user.id)]),
              ],
            },
          },
        },
        users: {
          $merge: users.reduce(
            (obj, user) => ({
              ...obj,
              [user.id]: user,
            }),
            {}
          ),
        },
      });
    }
    case Types.USER_AUTH_CHANGE_TYPE_SUCCESS:
      return {
        ...state,
        userType: action.userType,
      };
    case Types.USER_AUTH_GET_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case Types.USER_AUTH_GET_SETTINGS_SUCCESS:
      return {
        ...state,
        userType: action.settings.user_type,
        user_table: action.settings.auth_table_type,
        installers: action.settings.installers,
        directoryConfigured: action.settings.directory_configured,
        loading: false,
      };
    case Types.USER_AUTH_GET_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.USER_AUTH_GET_SYNC_TOOL:
      return {
        ...state,
        downloading: true,
      };
    case Types.USER_AUTH_GET_SYNC_TOOL_SUCCESS:
      return {
        ...state,
        downloading: false,
        synctool: action.synctool,
      };
    case Types.USER_AUTH_GET_SYNC_TOOL_FAILURE:
      return {
        ...state,
        downloading: false,
      };
    case Types.USER_AUTH_DELETE_GROUP_SUCCESS:
      return update(state, {
        groups: { $unset: [action.group] },
      });
    case Types.USER_AUTH_LOAD_USERS:
      return {
        ...state,
        loading: true,
      };
    case Types.USER_AUTH_LOAD_USERS_SUCCESS: {
      const userData = action.userData.reduce(
        (obj, agg) => ({
          ...obj,
          [agg.key]: {
            ...agg.last_seen.hits.hits[0]['_source'],
          },
        }),
        {}
      );

      return {
        ...state,
        loading: false,
        groups: action.groups.reduce(
          (obj, group) => ({
            ...obj,
            [group.id]: group,
          }),
          {}
        ),
        users: action.users.reduce(
          (obj, user) => ({
            ...obj,
            [user.id]: {
              ...user,
              ...(userData[user.id] || {}),
            },
          }),
          {}
        ),
      };
    }
    case Types.USER_AUTH_LOAD_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

function* addUsers() {
  try {
    const [userAuthentication, accountID, groups] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
      select(getGroupList),
    ]);

    const { changes } = userAuthentication.modal;
    let { group } = changes;

    if (!group) {
      group = groups.find(group => group.label === 'Users');
    }

    // prettier-ignore
    if (group['__isNew__']) { // eslint-disable-line
      group = yield call(Api.accounts.localGroups.create, accountID, {
        name:  group.value,
      });
      yield put(Types.createGroupSuccess({
        ...group,
        users: []
      }));
    } else {
      group = { id: group.value, name: group.label };
    }

    const users = hasOwn(changes, 'users')
      ? changes.users.split(',').map(user => user.replace(/(^\s+|\s+$)/g, ''))
      : [];

    let groupUsers;
    if (users.length) {
      const userResponse = yield call(
        Api.accounts.localGroups.addUsers,
        accountID,
        group.id,
        { users }
      );
      groupUsers = userResponse.users;
    } else {
      groupUsers = [];
    }

    yield all([
      put(Types.hideModal()),
      put(Types.addUsersSuccess(group, groupUsers)),
    ]);
  } catch (e) {
    yield put(Types.addUsersFailure('Failed to add users'));
  }
}

function* updateUser() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data, changes } = userAuthentication.modal;

    yield call(Api.accounts.localUsers.update, accountID, data.id, changes);
    yield all([
      put(
        Types.updateUserSuccess({
          id: data.id,
          ...changes,
        })
      ),
      put(Types.hideModal()),
    ]);
  } catch (e) {
    yield put(Types.updateUserFailure('Failed to update user name'));
  }
}

function* updateGroup() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data, changes } = userAuthentication.modal;

    yield call(Api.accounts.localGroups.update, accountID, data.id, changes);
    yield all([
      put(
        Types.updateGroupSuccess({
          id: data.id,
          ...changes,
        })
      ),
      put(Types.hideModal()),
    ]);
  } catch (e) {
    yield put(Types.updateGroupFailure('Failed to update group name'));
  }
}

function* removeFromGroup() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data } = userAuthentication.modal;

    const response = yield call(
      Api.accounts.localGroups.removeUsers,
      accountID,
      data.group.id,
      data.user.id
    );

    yield all([
      put(
        Types.removeFromGroupSuccess(
          data.user.id,
          data.group.id,
          response.deleted
        )
      ),
      put(Types.hideModal()),
    ]);
  } catch (e) {
    yield put(Types.removeFromGroupFailure('Failed to remove user from group'));
  }
}

function* moveUser() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data, changes } = userAuthentication.modal;

    const newGroup = changes.groupID;
    const oldGroup = data.groupID;

    yield call(Api.accounts.localGroups.addUsers, accountID, newGroup, {
      users: [data.email],
    });
    yield call(
      Api.accounts.localGroups.removeUsers,
      accountID,
      oldGroup,
      data.id
    );

    yield all([
      put(Types.moveUserSuccess(data.id, oldGroup, newGroup)),
      put(Types.hideModal()),
    ]);
  } catch (e) {
    yield put(Types.updateUserFailure('Failed to move user'));
  }
}

function* deleteGroup() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data } = userAuthentication.modal;

    yield call(Api.accounts.localGroups.delete, accountID, data.id);

    yield all([put(Types.deleteGroupSuccess(data.id)), put(Types.hideModal())]);
  } catch (e) {
    yield put(Types.deleteGroupFailure(e));
  }
}

function* getSettings() {
  try {
    const accountID = yield select(getAccountId);
    const [result, groups, users, userData, accountStatus] = yield all([
      call(Api.accounts.read, accountID),
      call(Api.accounts.localGroups.read, accountID),
      call(Api.accounts.localUsers.read, accountID),
      call(Api.accounts.localUsers.userData, {
        page: 'local_users',
        account: accountID,
      }),
      call(Api.accounts.progress, accountID),
    ]);

    yield all([
      put(Types.getSettingsSuccess(result)),
      put(Types.loadUsersSuccess(groups.groups, users.users, userData)),
      put(Types.loadAccountStatusSuccess(accountStatus)),
    ]);
    const synctool = yield call(Api.accounts.synctool, accountID);
    yield put(Types.getSyncToolSuccess(synctool));
  } catch (e) {
    yield all([
      put(Types.getSettingsFailure(e)),
      put(Types.loadUsersFailure(e)),
    ]);
  }
}

function* changeType() {
  try {
    const [userAuthentication, accountID] = yield all([
      select(getUserAuthentication),
      select(getAccountId),
    ]);
    const { data } = userAuthentication.modal;
    let updates = {};
    updates.local_users = data.type === 'local';
    updates.sync_tool = data.type === 'synctool';
    yield call(Api.accounts.update, accountID, updates);

    yield call(Api.accounts.localUsers.clearall, accountID);
    yield call(Api.accounts.localGroups.clearall, accountID);

    const [groups, users, userData, accountStatus] = yield all([
      call(Api.accounts.localGroups.read, accountID),
      call(Api.accounts.localUsers.read, accountID),
      call(Api.accounts.localUsers.userData, {
        page: 'local_users',
        account: accountID,
      }),
      call(Api.accounts.progress, accountID),
    ]);

    yield all([
      put(Types.changeTypeSuccess(data.type)),
      put(Types.loadUsersSuccess(groups.groups, users.users, userData)),
      put(Types.hideModal()),
      put(Types.loadAccountStatusSuccess(accountStatus)),
    ]);
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.changeTypeFailure(e));
  }
}

function* filterUsers() {
  try {
    const store = yield select();
    const accountID = yield select(getAccountId);
    const { userAuthentication } = store;

    yield delay(500);
    yield put(Types.loading());
    let users = yield call(Api.directory.readUsers, {
      account_id: store.account.selected,
      query: userAuthentication.query,
    });

    let fetchIds = [];
    for (let i = 0; i < users.users.length; i++) {
      fetchIds.push(users.users[i]['id']);
    }

    let groupsWithUser = { groups: [] };

    if (fetchIds.length > 0) {
      groupsWithUser = yield call(Api.directory.readGroups, {
        account_id: store.account.selected,
        user_ids: fetchIds,
      });
    }

    yield put(
      Types.loadUsersSuccess(groupsWithUser['groups'], users.users, [])
    );
  } catch (e) {
    console.log('errors: ', e);
    yield put(Types.changeTypeFailure(e));
  }
}
export function* UserAuthenticationFlow() {
  yield takeEvery(Types.USER_AUTH_ADD_USERS, addUsers);
  yield takeEvery(Types.USER_AUTH_UPDATE_USER, updateUser);
  yield takeEvery(Types.USER_AUTH_UPDATE_GROUP, updateGroup);
  yield takeEvery(Types.USER_AUTH_MOVE_USER, moveUser);
  yield takeEvery(Types.USER_AUTH_REMOVE_FROM_GROUP, removeFromGroup);
  yield takeEvery(Types.USER_AUTH_DELETE_GROUP, deleteGroup);
  yield takeEvery(Types.USER_AUTH_GET_SETTINGS, getSettings);
  yield takeEvery(Types.USER_AUTH_CHANGE_TYPE, changeType);
  yield takeLatest(Types.USER_AUTH_UPDATE_QUERY, filterUsers);
}
