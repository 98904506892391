import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard';

const mapStateToProps = (state, ownProps) => ({
  subscriptions: state.account.subscriptions,
  isMigrated: state.account.migrate_to_secure_edge,
  msp: state.account.msps,
});

export default connect(
  mapStateToProps,
  undefined
)(Dashboard);
